import React from 'react';
import styles from './App.module.scss';
import {InstagramLogo, TiktokLogo, TwitterLogo} from "phosphor-react";

function App() {
  return (
    <div>
      <header/>
      <main>
        <h1 className={styles.title}>Coming Soon: The Suya Company</h1>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <iframe className={styles.form}
                    src="https://tally.so/embed/3leyEv?transparentBackground=1"
                    title="Subscribe for Updates"
            />
            <div className={styles.socialContainer}>
              <a href="https://twitter.com"
                 target="_blank"
                 rel="noreferrer"
                 className={styles.socialLink}
              >
                <TwitterLogo size={"10rem"} />
              </a>
              <a href="https://tiktok.com"
                 target="_blank"
                 rel="noreferrer"
                 className={styles.socialLink}
              >
                <TiktokLogo size={"10rem"} />
              </a>
              <a href="https://instagram.com/thesuyaco"
                 target="_blank"
                 rel="noreferrer"
                 className={styles.socialLink}
              >
                <InstagramLogo size={"10rem"} />
              </a>
            </div>
          </div>

        </div>
      </main>
    </div>
  );
}
export default App;
